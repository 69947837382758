exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-careers-backend-developer-tsx": () => import("./../../../src/pages/careers/backend-developer.tsx" /* webpackChunkName: "component---src-pages-careers-backend-developer-tsx" */),
  "component---src-pages-careers-brand-manager-tsx": () => import("./../../../src/pages/careers/brand-manager.tsx" /* webpackChunkName: "component---src-pages-careers-brand-manager-tsx" */),
  "component---src-pages-careers-business-developer-tsx": () => import("./../../../src/pages/careers/business-developer.tsx" /* webpackChunkName: "component---src-pages-careers-business-developer-tsx" */),
  "component---src-pages-careers-digital-product-designer-tsx": () => import("./../../../src/pages/careers/digital-product-designer.tsx" /* webpackChunkName: "component---src-pages-careers-digital-product-designer-tsx" */),
  "component---src-pages-careers-index-tsx": () => import("./../../../src/pages/careers/index.tsx" /* webpackChunkName: "component---src-pages-careers-index-tsx" */),
  "component---src-pages-careers-junior-digital-product-designer-tsx": () => import("./../../../src/pages/careers/junior-digital-product-designer.tsx" /* webpackChunkName: "component---src-pages-careers-junior-digital-product-designer-tsx" */),
  "component---src-pages-careers-mobile-developer-tsx": () => import("./../../../src/pages/careers/mobile-developer.tsx" /* webpackChunkName: "component---src-pages-careers-mobile-developer-tsx" */),
  "component---src-pages-careers-open-application-tsx": () => import("./../../../src/pages/careers/open-application.tsx" /* webpackChunkName: "component---src-pages-careers-open-application-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-cookies-tsx": () => import("./../../../src/pages/cookies.tsx" /* webpackChunkName: "component---src-pages-cookies-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-life-at-mabiloft-tsx": () => import("./../../../src/pages/life-at-mabiloft.tsx" /* webpackChunkName: "component---src-pages-life-at-mabiloft-tsx" */),
  "component---src-pages-manifesto-tsx": () => import("./../../../src/pages/manifesto.tsx" /* webpackChunkName: "component---src-pages-manifesto-tsx" */),
  "component---src-pages-newsletter-tsx": () => import("./../../../src/pages/newsletter.tsx" /* webpackChunkName: "component---src-pages-newsletter-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-services-flutter-tsx": () => import("./../../../src/pages/services/flutter.tsx" /* webpackChunkName: "component---src-pages-services-flutter-tsx" */),
  "component---src-pages-works-bonusx-tsx": () => import("./../../../src/pages/works/bonusx.tsx" /* webpackChunkName: "component---src-pages-works-bonusx-tsx" */),
  "component---src-pages-works-intesa-sanpaolo-assicura-tsx": () => import("./../../../src/pages/works/intesa-sanpaolo-assicura.tsx" /* webpackChunkName: "component---src-pages-works-intesa-sanpaolo-assicura-tsx" */),
  "component---src-pages-works-tsx": () => import("./../../../src/pages/works.tsx" /* webpackChunkName: "component---src-pages-works-tsx" */),
  "component---src-templates-article-article-tsx": () => import("./../../../src/templates/Article/Article.tsx" /* webpackChunkName: "component---src-templates-article-article-tsx" */),
  "component---src-templates-case-study-tsx": () => import("./../../../src/templates/case_study.tsx" /* webpackChunkName: "component---src-templates-case-study-tsx" */)
}

